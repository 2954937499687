// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  // Initialize Firebase
  firebase: {
    apiKey: "AIzaSyAdzkM45EIjZ0O7ccS4UhXqGN2MIaRvXRE",
    authDomain: "nisarg-desai-website.firebaseapp.com",
    databaseURL: "https://nisarg-desa-website.firebaseio.com",
    projectId: "nisarg-desai-website",
    storageBucket: "nisarg-desai-website.appspot.com",
    messagingSenderId: "1027845644606"
  }
  // var config = {
  //   apiKey: "AIzaSyAdzkM45EIjZ0O7ccS4UhXqGN2MIaRvXRE",
  //   authDomain: "nisarg-desai-website.firebaseapp.com",
  //   databaseURL: "https://nisarg-desai-website.firebaseio.com",
  //   projectId: "nisarg-desai-website",
  //   storageBucket: "nisarg-desai-website.appspot.com",
  //   messagingSenderId: "1027845644606"
  // };
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
